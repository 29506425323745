import React from 'react';
import '../css/Footer.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faWhatsapp, faTiktok } from "@fortawesome/free-brands-svg-icons"

function Footer() {
  return (
    <footer>
      <div className="footerContainer">
        <div className="footerRow">
          <div className="footerCol">
            <h5>Colégio Líder</h5>
            <p>Você sempre a frente!</p>
          </div>
          <div className="socialIcons">
            <a href="https://www.instagram.com/colegiolidergo/" target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon={faInstagram} style={{ color: "#E1306C", fontSize: 20 }} />
            </a>
            <a href="https://wa.me/5562998271550" target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon={faWhatsapp} style={{ color: "#25D366", fontSize: 20, }} />
            </a>
            <a href="https://www.tiktok.com/@colegiolidergo" target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon={faTiktok} style={{ color: "#000000", marginBottom: 1 }} />
            </a>
          </div>
        </div>
        <div className="footerRow">
          <div className="footerCol">
            <h5>Institucional</h5>
            <ul className="footerLinks">
              <li><a href="/" className="footerLink">Início</a></li>
              <li><a href="/matricule-se" className="footerLink">Matricule-se</a></li>
              <li><a href="/contato" className="footerLink">Contato</a></li>
              <li><a href="https://aluno.escolarmanageronline.com.br/colegiolidergo" target='_blank' rel='noreferrer' className="footerLink">Aluno Online</a></li>
              <li><a href="https://portaldoprofessor.escolarmanageronline.com.br/Login" target='_blank' rel='noreferrer' className="footerLink">Portal do Professor</a></li>
            </ul>
          </div>
          <div className="footerCol">
            <h5>Ensino</h5>
            <ul className="footerLinks">
              <li><a href="/ensino-fundamental-1" className="footerLink">Ensino Fundamental 1</a></li>
              <li><a href="/ensino-fundamental-2" className="footerLink">Ensino Fundamental 2</a></li>
              <li><a href="/ensino-medio" className="footerLink">Ensino Médio</a></li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="footerRow direitos">
          <div className="footerCol">
            <p>Contato<br />
                <a style={{ color: 'rgb(245, 134, 52)' }} target='_blank' rel='noreferrer' href="tel:6235480070">(62) 3548-0070</a><br/>
                <a style={{ color: 'rgb(245, 134, 52)' }} target='_blank' rel='noreferrer' href="https://wa.me/5562998271550">(62) 99827-1550</a><br/>
                <a style={{ color: 'rgb(245, 134, 52)' }} target='_blank' rel='noreferrer' href="/contato">Localização</a>
            </p>
            <p>Copyright &copy; Colégio Líder - 2024 Todos os direitos reservados.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;