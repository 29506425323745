import React, { useState, useEffect, useCallback } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import '../css/LivrosCarrossel.css'; // Importe seu arquivo CSS aqui
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';

const LivrosCarousel = ({ livros }) => {
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [availableGrades, setAvailableGrades] = useState([]);
  const [normalizedLivros, setNormalizedLivros] = useState([]);
  const [swiperKey, setSwiperKey] = useState(0);

  // Função para obter o sufixo da URL do site
  const getSeriesFromUrl = () => {
    const url = window.location.href;
    if (url.endsWith('ensino-fundamental-1')) {
      return 'F1';
    } else if (url.endsWith('ensino-fundamental-2')) {
      return 'F2';
    } else if (url.endsWith('ensino-medio')) {
      return 'M';
    } else {
      return '';
    }
  };

  // Função para definir as opções de ano com base na série
  const getSeriesOptions = (series) => {
    switch (series) {
      case 'F1':
        return [
          { value: '1 ano', label: '1° Ano' },
          { value: '2 ano', label: '2° Ano' },
          { value: '3 ano', label: '3° Ano' },
          { value: '4 ano', label: '4° Ano' },
          { value: '5 ano', label: '5° Ano' }
        ];
      case 'F2':
        return [
          { value: '6 ano', label: '6° Ano' },
          { value: '7 ano', label: '7° Ano' },
          { value: '8 ano', label: '8° Ano' },
          { value: '9 ano', label: '9° Ano' }
        ];
      case 'M':
        return [
          { value: '1 médio', label: '1° Ano' },
          { value: '2 médio', label: '2° Ano' },
          { value: '3 médio', label: '3° Ano' }
        ];
      default:
        return [];
    }
  };

  // Atualiza as opções de ano com base na série detectada
  const updateAvailableGrades = useCallback(() => {
    const detectedSeries = getSeriesFromUrl();
    setAvailableGrades(getSeriesOptions(detectedSeries));
  }, []);

  // Funções de formatação e ordenação
  const formatSeries = (series) => {
    switch (series) {
      case 'F1':
        return 'Fundamental I';
      case 'F2':
        return 'Fundamental II';
      case 'M':
        return 'Ensino Médio';
      default:
        return 'Série Desconhecida';
    }
  };

  const formatGrade = (grade) => {
    return grade
      .replace(/^([1-9])/, '$1°') // Adiciona "°" após o número
      .replace(/médio/i, 'Ano');   // Substitui "médio" por "Ano"
  };

  const isEmptyOrWhitespace = (str) => !str || !str.trim();

  const sortBooks = (a, b) => {
    const gradeComparison = a.book.grade.localeCompare(b.book.grade);
  
    if (gradeComparison !== 0) {
      return gradeComparison; // Ordena por série
    }
  
    // Se estiverem na mesma série, ordena por tipo (literário primeiro)
    const aIsLiterario = !isEmptyOrWhitespace(a.book.author);
    const bIsLiterario = !isEmptyOrWhitespace(b.book.author);
  
    if (aIsLiterario !== bIsLiterario) {
      return aIsLiterario ? -1 : 1; // Literário vem primeiro
    }
  
    return 0; // Se são do mesmo tipo, mantém a ordem original
  };  

  // Função para lidar com mudanças na seleção de ano
  const handleGradeChange = (event) => {
    setSelectedGrade(event.target.value);
    setSwiperKey((prevKey) => prevKey + 1); // Atualiza a chave do Swiper
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
    setSwiperKey((prevKey) => prevKey + 1); // Atualiza a chave do Swiper
  };

  // Função para extrair o ID do arquivo do link do Google Drive
  const extractFileId = (url) => {
    const regex = /\/d\/(.*?)\/view/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  // Função para construir o link direto da imagem
  const buildImageUrl = (fileId) => {
    return `https://drive.google.com/thumbnail?id=${fileId}`;
  };

  // Filtra e ordena os livros
  const filteredLivros = normalizedLivros
    .filter((livro) => {
      const matchesGrade = !selectedGrade || livro.book.grade === selectedGrade;
      const matchesType = !selectedType || 
        (selectedType === 'didatico' && isEmptyOrWhitespace(livro.book.author)) || 
        (selectedType === 'literario' && !isEmptyOrWhitespace(livro.book.author));
      return matchesGrade && matchesType;
    })
    .sort(sortBooks);

  useEffect(() => {
    const normalized = livros.flat();
    setNormalizedLivros(normalized);
    updateAvailableGrades();
  }, [livros, updateAvailableGrades]);

  return (
    <div className="livros-carousel">
      <div className="filters">
        <div className="grade-select">
          <label htmlFor="grade">Selecione o Ano:</label>
          <select id="grade" value={selectedGrade} onChange={handleGradeChange}>
            <option value="">Todos os Anos</option>
            {availableGrades.map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
        <div className="type-select">
          <label htmlFor="type">Tipo de Livro:</label>
          <select id="type" value={selectedType} onChange={handleTypeChange}>
            <option value="">Todos os Tipos</option>
            <option value="didatico">Didático</option>
            <option value="literario">Literário</option>
          </select>
        </div>
      </div>

      {filteredLivros.length === 0 ? (
        <p>Carregando...</p>
      ) : (
        <Swiper
          key={swiperKey}
          modules={[Navigation]}
          spaceBetween={10}
          pagination={{ clickable: true }}
          showsButtons={true}
          navigation
          breakpoints={{
            700: { slidesPerView: 3 },
          }}
          slidesPerView={1}
        >
          {filteredLivros.map((livro, index) => {
            const imageId = extractFileId(livro.imageLink);
            const imageUrl = imageId ? buildImageUrl(imageId) : '';
            console.log(imageUrl)

            return (
              <SwiperSlide key={livro.book.id || `livro-${index}`} className="livro-slide">
                <div className="livro-text">
                  <h4 className="livro-name">{livro.book.title}</h4>
                  {imageUrl && (
                    <img
                      src={imageUrl}
                      alt={livro.book.title}
                      className="livro-image"
                    />
                  )}
                  {!isEmptyOrWhitespace(livro.book.author) && (
                    <h5 className="livro-author"><strong>Autor:</strong> {livro.book.author}</h5>
                  )}
                  {!isEmptyOrWhitespace(livro.book.publisher) && (
                    <h5 className="livro-author"><strong>Editora:</strong> {livro.book.publisher}</h5>
                  )}
                  {!isEmptyOrWhitespace(livro.book.year) && (
                    <h5 className="livro-pub-year"><strong>Ano de Publicação:</strong> {livro.book.year}</h5>
                  )}
                  <h5 className="livro-series">{livro.book.series && (
                    <h5 className="livro-series">{formatSeries(livro.book.series)}</h5>
                  )}</h5>
                  <h5 className="livro-grade">{livro.book.grade && (
                    <h5 className="livro-grade">{formatGrade(livro.book.grade)}</h5>
                  )}</h5>
                  {livro.book.pdf && (
                    <a className='pdf-botao' style={{width: 20 + "%"}} target='_blank' href={livro.book.pdf}>PDF</a>
                  )}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <a href='livros' rel='noreferrer' className='view-all-button'>
          <FontAwesomeIcon icon={faBook} /> Ver Todos os Livros
        </a>
      </div>
    </div>
  );
};

export default LivrosCarousel;