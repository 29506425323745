import React, { useState, useEffect, useLocation } from 'react';
import '../css/LivrosGrid.css';

const buildImageUrl = (fileId) => {
  return `https://drive.google.com/thumbnail?id=${fileId}`;
};

const getFileIdFromUrl = (url) => {
  const match = url.match(/[-\w]{25,}/);
  return match ? match[0] : null;
};

const LivrosGrid = ({ livros }) => {
  const [selectedGrade, setSelectedGrade] = useState('1 ano');
  
  const availableGrades = [
    { value: '1 ano', label: '1° Ano Fund. I' },
    { value: '2 ano', label: '2° Ano Fund. I' },
    { value: '3 ano', label: '3° Ano Fund. I' },
    { value: '4 ano', label: '4° Ano Fund. I' },
    { value: '5 ano', label: '5° Ano Fund. I' },
    { value: '6 ano', label: '6° Ano Fund. II' },
    { value: '7 ano', label: '7° Ano Fund. II' },
    { value: '8 ano', label: '8° Ano Fund. II' },
    { value: '9 ano', label: '9° Ano Fund. II' },
    { value: '1 médio', label: '1° Ano Ens. Médio' },
    { value: '2 médio', label: '2° Ano Ens. Médio' },
    { value: '3 médio', label: '3° Ano Ens. Médio' }
  ];

  const filteredLivros = selectedGrade 
    ? livros.flatMap((grupo) => 
        grupo.filter((livro) => livro.book.grade === selectedGrade)
      )
    : [];

  // Ordena os livros: literários primeiro, didáticos depois
  const sortedLivros = filteredLivros.sort((a, b) => {
    const aIsSpecial = a.book.title.includes('Material') || a.book.title.includes('Caderno');
    const bIsSpecial = b.book.title.includes('Material') || b.book.title.includes('Caderno');
    
    return aIsSpecial === bIsSpecial ? 0 : aIsSpecial ? 1 : -1;
  });

  return (
    <div>
      <div className="filters">
        <div className="grade-select">
          <label htmlFor="grade">Selecione o Ano:</label>
          <select id="grade" value={selectedGrade} onChange={(e) => setSelectedGrade(e.target.value)}>
            {availableGrades.map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="books-grid">
        {sortedLivros.length === 0 ? (
          <p>Nenhum livro encontrado.</p>
        ) : (
          sortedLivros.map((livro) => {
            const fileId = getFileIdFromUrl(livro.imageLink);
            const thumbnailUrl = fileId ? buildImageUrl(fileId) : null;

            return (
              <div key={livro.book.title} className="book-item">
                <h4>{livro.book.title}</h4>
                {livro.book.author && (
                  <p><strong>Autor:</strong> {livro.book.author}</p>
                )}
                <p><strong>Editora:</strong> {livro.book.publisher}</p>
                <p><strong>Ano:</strong> {livro.book.year}</p>
                {livro.book.pdf && (
                  <a className='pdf-botao' target='_blank' href={livro.book.pdf}>Ler em PDF</a>
                )}
                {thumbnailUrl && (
                  <img 
                    src={thumbnailUrl} 
                    alt={livro.book.title} 
                  />
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default LivrosGrid;