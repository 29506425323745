import React, { useRef, useEffect } from 'react';
import './Matricula.css';
import Header from '../../componentes/js/Header';
import Footer from '../../componentes/js/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGraduate, faChalkboardTeacher, faClipboardList, faSchool, faPeopleRoof, faHandHoldingHeart, faBookReader } from '@fortawesome/free-solid-svg-icons';

const Matricula = () => {
  const embedRef = useRef(null);

  useEffect(() => {
    if (embedRef.current) {  // Certifica-se que o ref não está null
      const script = document.createElement('script');
      script.src = "https://www.instagram.com/embed.js";
      script.async = true;
      script.defer = true;
      embedRef.current.appendChild(script);

      return () => {
        embedRef.current.removeChild(script);
      };
    }
  }, []);

  return (
    <div>
      <Header />

      {/* Hero Section */}
      <section className="hero" style={{ backgroundImage: "url('assets/images/banners/matricula.png')" }}>
        <a href="https://wa.me/5562998271550" target='_blank' rel='noreferrer' className="cta-button">Faça sua Matrícula</a>
      </section>

      <div id='botao-mobile' style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#F4F4F4' }}>
        <a id="botao-mobile" href="#diferenciais" className="cta-button">Saiba Mais</a>
      </div>

      {/* Benefícios Section */}
      <section id="diferenciais" className="benefits">
        <h2>Por que escolher nosso colégio?</h2>
        <div className="benefit-cards centralizar-div">
          <div className="card-mat">
            <div className="card-content-mat">
              <FontAwesomeIcon icon={faUserGraduate} className="icon" />
              <p>Nossos alunos têm os melhores resultados acadêmicos e formação integral.</p>
            </div>
            <div className='image-content-mat'>
              <img src="assets/images/landing/1.jpg" className="card-image" alt="Benefício 1" />
            </div>
          </div>
          <div className="card-mat">
            <div className="card-content-mat">
              <FontAwesomeIcon icon={faChalkboardTeacher} className="icon" />
              <p>Contamos com uma equipe altamente qualificada e dedicada ao aprendizado de cada aluno.</p>
            </div>
            <div className='image-content-mat'>
              <img src="assets/images/landing/2.jpg" className="card-image" alt="Benefício 2" />
            </div>
          </div>
          <div className="card-mat">
            <div className="card-content-mat">
              <FontAwesomeIcon icon={faClipboardList} className="icon" />
              <p>Ambientes pensados para o desenvolvimento completo dos estudantes.</p>
            </div>
            <div className='image-content-mat'>
              <img src="assets/images/landing/3.png" className="card-image" alt="Benefício 3" />
            </div>
          </div>
        </div>
      </section>

      <div id='separador-matricula'>
        <div class="stat-item">
          <span class="stat-number">+10</span>
          <p>Anos de excelência</p>
        </div>
        <div class="stat-item">
          <span class="stat-number">+25</span>
          <p>Professores qualificados</p>
        </div>
        <div class="stat-item">
          <span class="stat-number">+500</span>
          <p>Alunos formados</p>
        </div>
      </div>

      {/* Depoimentos Section */}
      <section className="testimonials">
        <div style={{ textAlign: 'center', marginTop: 15 + 'px' }}>
          <h2>O que os pais dizem sobre nós</h2>
        </div>
        <div className="testimonial-cards">
          <div class="testimonial-card">
            <div class="testimonial-content">
                <p>"Excelente Colégio. Moderno e com uma educação próxima para as crianças."</p>
                <div class="testimonial-author">
                    <div class="author-initials">DS</div>
                    <div class="author-details">
                        <strong>Daniella Santana</strong>
                        <span>Mãe de aluno</span>
                    </div>
                </div>
            </div>
          </div>
          <div class="testimonial-card">
            <div class="testimonial-content">
                <p>"Até agora sem reclamações! Gostei muito da escola e principalmente do acolhimento e educação da coordenação."</p>
                <div class="testimonial-author">
                    <div class="author-initials">ES</div>
                    <div class="author-details">
                        <strong>Elaine Souza</strong>
                        <span>Mãe de aluno</span>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div ref={embedRef} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: 20 + 'px', gap: 30 + 'px'}}>
              <blockquote
                className="instagram-media"
                data-instgrm-permalink="https://www.instagram.com/reel/C9U9dKasVxT/?utm_source=ig_embed"
                data-instgrm-version="14"
              />
              <blockquote
                className="instagram-media"
                data-instgrm-permalink="https://www.instagram.com/reel/C9F35tTsrmT/?utm_source=ig_embed"
                data-instgrm-version="14"
              />
          </div>
      </section>

      {/* Seção de Diferenciais */}
      <section className="extra-info">
        <h2>Nossos Diferenciais</h2>
        <div className="info-cards">
          <div className="info-card">
            <FontAwesomeIcon icon={faBookReader} className="icon" />
            <h3>Atividades Extracurriculares</h3>
            <p>Além do currículo básico, oferecemos aulas de robótica, música, esportes e mais, proporcionando uma educação completa.</p>
          </div>
          <div className="info-card">
            <FontAwesomeIcon icon={faHandHoldingHeart} className="icon" />
            <h3>Foco no Desenvolvimento Emocional</h3>
            <p>Sabemos que a formação de caráter e emoções é essencial. Por isso, investimos em programas que ajudam no desenvolvimento social e emocional.</p>
          </div>
          <div className="info-card">
            <FontAwesomeIcon icon={faPeopleRoof} className="icon" />
            <h3>Parceria com Pais</h3>
            <p>Trabalhamos em conjunto com os pais para garantir o melhor acompanhamento pedagógico e desenvolvimento de cada aluno.</p>
          </div>
          <div className="info-card">
            <FontAwesomeIcon icon={faSchool} className="icon" />
            <h3>Infraestrutura Moderna</h3>
            <p>Criamos ambientes que estimulam uma aprendizagem dinâmica e integrada à tecnologia.</p>
          </div>
        </div>
      </section>

      <section className="texto">
      <h2 style={{ marginTop: '10px', fontSize: '2.5rem', color: '#2d2a7b' }}>Transformando o Futuro</h2>
      <div className="texto-container">
        <p className="texto-paragrafo">
          No Colégio Líder, acreditamos que a educação vai muito além da transmissão de conteúdo. Nosso compromisso é formar
          indivíduos críticos, criativos e preparados para os desafios do mundo moderno. Proporcionamos um ambiente de aprendizado
          que desperta a curiosidade, incentivando nossos alunos a explorarem suas paixões e desenvolverem habilidades essenciais para o futuro.
        </p>
        <p className="texto-paragrafo">
          Com uma infraestrutura moderna, contamos com salas de aula equipadas, espaços inovadores e uma gama de atividades extracurriculares,
          permitindo que os estudantes se expressem, se envolvam e cresçam em diversos campos do saber.
        </p>
        <p className="texto-paragrafo">
          Acreditamos que a educação deve ser uma jornada colaborativa, que envolve a união de alunos, educadores, famílias e a comunidade.
          Nosso ambiente é inclusivo, onde cada voz é ouvida, respeitada e valorizada. Estamos prontos para atender diferentes estilos e
          necessidades de aprendizado, garantindo que todos tenham as mesmas oportunidades de brilhar.
        </p>
        <p className="texto-paragrafo">
          Venha fazer parte dessa transformação! Juntos, podemos construir um futuro brilhante e cheio de possibilidades para nossos alunos.
        </p>
      </div>
      <div className="chamada-para-acao" style={{marginTop: 30 + 'px'}}>
        <a href="https://wa.me/5562998271550" target='_blank' className="cta-button">Faça sua Matrícula Agora!</a>
      </div>
    </section>

      <Footer />
    </div>
  );
};

export default Matricula;